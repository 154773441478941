import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <SearchEngineOptimization
        title=""
        description=""
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section>
        <div className="z-10 flex w-full items-center justify-between px-4 pt-8 md:absolute md:px-10">
          <Link to="/">
            <StaticImage
              src="../images/Global/Nav/logo.png"
              alt="Nikkael Home Loans Logo"
              loading="eager"
              width={130}
              className="w-[98px] md:w-[130px]"
            />
          </Link>

          <Link to="/" className="group no-underline">
            <i className="far fa-times text-2xl text-black transition-all duration-300 ease-linear group-hover:text-primary-500"></i>
          </Link>
        </div>

        <div className="relative grid text-center md:h-screen md:grid-cols-2">
          <div className="flex items-center justify-center bg-white py-24 px-6 md:py-16 md:px-8 lg:px-12">
            <div className="mx-auto max-w-[536px]">
              <div className="mb-3.5 text-sm font-bold uppercase tracking-wide text-gray-500">
                I want to
              </div>
              <div className="heading-one mb-3.5">Purchase a Home</div>
              <p>
                Are you ready to start the loan process so you can begin looking
                for your new home? Tell us a little about yourself and your
                goals, and we'll help you get qualified.
              </p>
              <ButtonSolid href="/purchase/" text="Purchase" />
            </div>
          </div>

          <div className="relative flex items-center justify-center bg-secondary-50 py-24 px-6 md:py-16 md:px-8 lg:px-12">
            <div className="mx-auto max-w-[536px]">
              <div className="mb-3.5 text-sm font-bold uppercase tracking-wide text-gray-500">
                I want to
              </div>
              <div className="heading-one mb-3.5">Refinance my Home</div>
              <p>
                Get better terms, lower your monthly mortgage payment, or make
                your escrow work for you with a reverse mortgage (for homeowners
                62 and older). This is the place to start.
              </p>
              <ButtonSolid href="/refinance/" text="Refinance" altStyle={2} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-global.jpg" }
    ) {
      publicURL
    }
  }
`;
export default Page;
